import * as React from "react"

//styles
import "../styles/css/styles.css"


// components
import Header from "../components/Header"
import Footer from "../components/Footer"
import defaultsData from '../utils/formStructure';
import Form from "../components/shared/Form";
import Search from "../components/Search/index";

import ChannelOpen from "../components/ChannelOpen";
import config from "../utils/config";
import { Helmet } from 'react-helmet';


export default function Contato() {

  return(
    <div>
      <Helmet>
          <title>Canal Aberto - Wega</title>
          <link rel="shortcut icon" href={config.favicon} type="image/x-icon"></link>
      </Helmet>
      <Header />
      <Search />
        <ChannelOpen />
      <Footer />
    </div>
  )
}

export const Head = () => <title>Wega Motors</title>